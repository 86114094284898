import React from 'react';

import { MODALS, PATHNAMES } from '../utils/constants';
import ClipboardButton from './ClipboardButton';

function getUserRoles(roles) {
    if (!roles) return 'none';

    const keys = Object.keys(roles);
    const filteredKeys = keys.filter(key => roles[key]);
    return filteredKeys.length ? filteredKeys.join(', ') : 'none';
}

function AccountDetails({ pathname, searchResult, setModalOpen }) {
    function accountNotFound() {
        return searchResult.firebaseUserUids.length < 1
        && searchResult.relatedAccounts.length < 1;
    }

    if (accountNotFound()) return (
        <div className="card">
            <div className="card__account-header">
                <h2 className="card__title">Account details</h2>
            </div>
            <div>No account found</div>
        </div>
    );

    function onClick() {
        const modal = pathname === PATHNAMES.hosts
            ? MODALS.hostEdit
            : MODALS.accountEdit;
        setModalOpen(modal);
    }

    const userUid = searchResult.firebaseUserUids.join(', ');
    return (
        <div className="card">
            <div className="card__account-header">
                <h2 className="card__title">Account details</h2>
                {searchResult.firebaseUserUids.length > 0 &&
                    <div className="card__button-container">
                        <button className="card__edit-button mdl-color-text--indigo-500" onClick={onClick}>
                            <span className="material-icons" style={{ marginRight: '4px' }}>
                                edit
                            </span>
                            Edit
                        </button>
                        <button className="card__delete-button"
                            onClick={() => setModalOpen(MODALS.accountDelete)}>
                            <span className="material-icons" style={{ marginRight: '4px' }}>
                                delete
                            </span>
                            Delete
                        </button>
                    </div>
                }
            </div>
            <div className="card__results">
                <div className="card__item-group">
                    <div>
                        <div className="card__item-group-key text-bold">Firebase User Uid:</div>
                        <span className="card__user-uid">
                            {userUid || 'n/a'}
                        </span>
                        <ClipboardButton value={userUid} />
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">User Email(s):</div>
                        <span>
                            <ul
                                style={{ display: 'inline-flex' }}
                                className="card__user-email-list card__array">
                                {searchResult.userEmails.map((userEmail, i) => {
                                    return (
                                        <li key={`userEmails${i}`}>
                                            {userEmail}
                                            <ClipboardButton value={userEmail} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    </div>
                    {pathname !== PATHNAMES.hosts && searchResult.relatedAccounts &&
                        <div>
                            <div className="card__item-group-key text-bold">Linked accounts:</div>
                            <span>
                                <ul
                                    style={{ display: 'inline-flex' }}
                                    className="card__related-accounts-list card__array">
                                        {searchResult.relatedAccounts.map((relatedAccount, i) => {
                                            const relatedEmail = relatedAccount.userEmails[0];
                                            return (
                                                <li key={`relatedAccounts${i}`}>
                                                    {relatedEmail}
                                                    <ClipboardButton value={relatedEmail} />
                                                </li>
                                            );
                                        })}
                                </ul>
                            </span>
                        </div>
                    }
                    {pathname === PATHNAMES.hosts &&
                        <>
                            <div>
                                <div className="card__item-group-key text-bold">Role:</div>
                                <span className="card__item-group-value">
                                    {getUserRoles(searchResult.roles)}
                                </span>
                            </div>
                            <div>
                                <div style={{ display: 'flex' }} className="card__item-group">
                                    <div className="card__item-group-key text-bold">Shows:</div>
                                    {searchResult.shows && searchResult.shows.length > 0 &&
                                        <ul className="card__array">
                                            {searchResult.shows.map((show, i) => {
                                                let showAlias = show;
                                                let hours = 0;
                                                if (typeof showAlias !== "string" ) {
                                                    showAlias = show.showAlias;
                                                    hours = show.hours;
                                                }

                                                return (
                                                    <li key={`shows${i}`}>
                                                        <a
                                                            className="card__item-group-value mdl-color-text--indigo-500"
                                                            href={`https://www.nts.live/shows/${showAlias}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {showAlias}
                                                        </a>
                                                        <span style={{ marginLeft: "6px" }}>
                                                            ({hours}hrs)
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }
                                    {(!searchResult.shows || searchResult.shows.length < 1) &&
                                        <span className="card__item-group-value">none</span>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default AccountDetails;
