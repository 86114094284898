import { ADMIN_PATHNAMES, HOST_PATHNAMES, BROADCAST_DETAIL_PATH_REGEX } from './constants';

function isAuthorised(roles, pathname) {
    const isBroadcastPathname = BROADCAST_DETAIL_PATH_REGEX.test(pathname);
    const isAdminPathname = ADMIN_PATHNAMES.includes(pathname) || isBroadcastPathname;
    const isHostPathname = HOST_PATHNAMES.includes(pathname) || isBroadcastPathname;

    if ((isAdminPathname && roles.admin)
        || (isHostPathname && roles.host)) return true;

    return false;
}

export {
    isAuthorised,
};
