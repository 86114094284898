const MODALS = {
    accountEdit: 'accountEdit',
    hostEdit: 'hostEdit',
    payout: 'payout',
    subscriptionLink: 'subscriptionLink',
    accountDelete: 'accountDelete',
    createBroadcast: 'createBroadcast',
};
const PATHNAMES = {
    devices: '/devices',
    home: '/',
    hosts: '/hosts',
    payout: '/payout',
    recommendations: '/recommendations',
    listenerPicks: '/listener-picks',
    broadcasts: '/broadcasts',
};
const ADMIN_PATHNAMES = [
    PATHNAMES.devices,
    PATHNAMES.home,
    PATHNAMES.hosts,
    PATHNAMES.recommendations,
    PATHNAMES.listenerPicks,
    PATHNAMES.broadcasts,
];
const HOST_PATHNAMES = [
    PATHNAMES.home,
    PATHNAMES.payout,
    PATHNAMES.broadcasts,
];
const BROADCAST_DETAIL_PATH_REGEX = new RegExp(/^\/broadcasts\/([\w\d]+)$/);

module.exports = {
    ADMIN_PATHNAMES,
    HOST_PATHNAMES,
    MODALS,
    PATHNAMES,
    BROADCAST_DETAIL_PATH_REGEX
};
