import React, { useState } from 'react';

import firebase from '../firebase';

function ExportButton() {
    const [isDownloading, setIsDownloading] = useState(false);

    function handleClick() {
        setIsDownloading(true);

        firebase.getDownloadHostsUrl()
            .then(url => {
                setIsDownloading(false);
                window.open(url);
            })
            .catch(err => {
                setIsDownloading(false);
                alert(`Something went wrong. ${err.message}`);
            });
    }

    return (
        <button
            className="mdl-button mdl-button--raised button-blue"
            onClick={handleClick}
            style={{ minWidth: '150px' }}
            disabled={isDownloading}>
            {!isDownloading && 'Export Hosts'}

            {isDownloading &&
                <i className="material-icons rotating" role="presentation">
                    rotate_right
                </i>
            }
        </button>
    );
}

export default ExportButton;
