import React from 'react';

function RecommendationsDetails({ recommendations }) {
    return (
        <div className="card">
            <h2 className="card__title">Recommendations</h2>
            <div className="card__results">
                <div style={{ display: 'flex' }} className="card__item-group">
                    <div className="card__item-group-key text-bold">Show alias(es):</div>
                    <ol style={{ margin: '0', paddingLeft: '16px' }}>
                        {recommendations.map((recommendation, i) => {
                            return (
                                <li key={`recommendations${i}`}>
                                    <a
                                        className="card__item-group-value mdl-color-text--indigo-500"
                                        href={`https://www.nts.live/shows/${recommendation}`}
                                        target="_blank" rel="noreferrer">
                                        {recommendation}
                                    </a>
                                </li>
                            );
                        })}
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default RecommendationsDetails;
