import React, { useState } from 'react';

import firebase from '../firebase';

function ConfirmDonateButton({ children, show, onSuccess }) {
    const [isProcessing, setIsProcessing] = useState(false);

    function handleClick() {
        setIsProcessing(true);
        firebase.confirmShowPayout({ donate: 'nts' }, show.showAlias, null, show.amountCents)
            .then(onSuccess)
            .catch(err => {
                setIsProcessing(false);
                alert(`Something went wrong. ${err.message}`);
            });
    }

    return (
        <button
            className="mdl-button mdl-button--raised button-blue button-responsive"
            onClick={handleClick}
            style={{ minWidth: '150px' }}
            disabled={isProcessing}>
            {isProcessing &&
                <i className="material-icons rotating" role="presentation">
                    rotate_right
                </i>
            }
            {!isProcessing && children}
        </button>
    );
}

export default ConfirmDonateButton;
