import React, { useState } from 'react';

import { PATHNAMES } from '../utils/constants';
import { sanitiseShowAlias } from '../utils/validationHelpers';
import firebase from '../firebase';

const EDITABLE_ROLES = [ 'host' ];

function hostShows(searchResult) {
    if (!searchResult || !searchResult.shows) return "";

    return searchResult.shows.reduce((aliases, show) => {
        if (typeof show === "string") {
            aliases.push(show);
        } else if (show && show.showAlias) {
            aliases.push(show.showAlias);
        }
        return aliases;
    }, []).join(", ");
}

function HostEditModal({ setSearchResult, searchResult, setModalOpen }) {
    const [roles, setRoles] = useState(searchResult.roles);
    const [showCsv, setShowCsv] = useState(hostShows(searchResult));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const customerEmail = searchResult.userEmails[0].trim().toLowerCase();

    function handleChange(e) {
        const { value } = e.target;
        setShowCsv(value);
    }

    function handleCheckboxChange(e) {
        const { checked, name } = e.target;
        setRoles({
            ...roles,
            [name]: checked,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);
        const shows = showCsv.split(',')
            .map(sanitiseShowAlias)
            .filter(showAlias => !!showAlias);
        if (shows.length > 10) {
            alert('Please enter a maximum of 10 shows.');
            setIsSubmitting(false);
            return;
        }

        if (showCsv.length > 0 && shows.length < 1) {
            alert('Please enter a valid show or comma-separated list of shows.');
            setIsSubmitting(false);
            return;
        }

        firebase.updateHostRole(customerEmail, !!roles.host, shows)
            .then(() => {
                return firebase.getCustomerInfo(customerEmail, PATHNAMES.hosts);
            })
            .then(response => {
                setSearchResult(response.data);
                setIsSubmitting(false);
                onClick();
            })
            .catch(err => {
                setIsSubmitting(false);
                alert(`Something went wrong. ${err.message}`);
            });
    }

    function onClick() {
        setModalOpen(null);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="edit-modal__title">
                        Edit account details
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="current-email">
                                Current email
                            </label>
                            <input
                                id="current-email"
                                className="mdl-textfield__input"
                                value={customerEmail}
                                readOnly
                                disabled />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="showCsv">
                                Shows
                            </label>
                            <input
                                id="showCsv"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name="showCsv"
                                value={showCsv}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield edit-modal__field">
                            <div className="mdl-typography--body-1">Role</div>
                            {Object.keys(roles).map(roleId => {
                                const disabled = !EDITABLE_ROLES.includes(roleId);
                                const disabledClass = disabled ? 'disabled' : '';
                                return (
                                    <label
                                        key={roleId}
                                        htmlFor={roleId}
                                        className={`edit-modal__checkbox-container ${disabledClass}`}>
                                        <input
                                            style={{ marginRight: '8px' }}
                                            id={roleId}
                                            className="edit-modal__input"
                                            type="checkbox"
                                            name={roleId}
                                            checked={roles[roleId]}
                                            onChange={handleCheckboxChange}
                                            disabled={disabled} />
                                        {roleId}
                                    </label>
                                );
                            })}
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Save
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default HostEditModal;
