class Roles {
    constructor(admin, host) {
        this.admin = admin;
        this.host = host;
    }
}

class AuthUser {
    constructor(firebaseUser, roles) {
        this.email = firebaseUser.email;
        this.roles = roles;
    }
}


function payoutDate(timeStamp) {
    if(!timeStamp) return null;

    const cutoffDate = new Date(timeStamp);
    const threeDaysMs = 3 * 24 * 3600 * 1000;
    return new Date(cutoffDate.getTime() + threeDaysMs);
}

class HostShow {
    constructor(hostShowData) {
        this.showAlias = hostShowData.showAlias;
        this.name = hostShowData.name;
        this.hours = hostShowData.hours;
        this.hoursConfirmed = hostShowData.hoursConfirmed;
        this.amountCents = hostShowData.amountCents;
        this.payoutConfirmed = hostShowData.payoutConfirmed;
        this.invoiceUrl = hostShowData.invoiceUrl;
        this.payoutAt = payoutDate(hostShowData.payoutAt);
    }
    get isPayoutPhase() {
        return this.amountCents > 0;
    }
    get isConfirmed() {
        return !this.isPayoutPhase && this.hoursConfirmed === this.hours
            || this.isPayoutPhase && !!this.payoutConfirmed;
    }
    get donationIsConfirmed() {
        return this.payoutConfirmed === 'donate';
    }
}

export {
    AuthUser,
    HostShow,
    Roles,
};
