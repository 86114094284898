import React, { useState } from 'react';

import firebase from '../firebase';

function CreateBroadcastModal({ setModalOpen, getUserBroadcasts }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [broadcastEmail, setBroadcastEmail] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        firebase.createBroadcast(broadcastEmail)
            .then(broadcastId => {
                if (!broadcastId) return alert("Something went wrong.");

                getUserBroadcasts();
            })
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
                setModalOpen(null);
            });
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value } = e.target;
        setBroadcastEmail(value);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <form onSubmit={handleSubmit}>
                        <div>
                            To link broadcast to an existing host user, enter their email address before hitting create.
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="email">
                                Email address
                            </label>
                            <input
                                id="email"
                                className="mdl-textfield__input edit-modal__input"
                                type="email"
                                name="email"
                                value={broadcastEmail}
                                onChange={handleChange} />
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Create
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateBroadcastModal;
