import React, { useState } from 'react';

import firebase from '../firebase';

function ConfirmShowHoursButton({ children, hours, showAlias, onSuccess }) {
    const [isProcessing, setIsProcessing] = useState(false);

    function handleClick() {
        setIsProcessing(true);
        firebase.confirmShowHours(hours, showAlias)
            .then(onSuccess)
            .catch(err => {
                setIsProcessing(false);
                alert(`Something went wrong. ${err.message}`);
            });
    }

    return (
        <button
            className="mdl-button mdl-button--raised button-blue"
            onClick={handleClick}
            style={{ minWidth: '150px' }}
            disabled={isProcessing || !hours}>
            {isProcessing &&
                <i className="material-icons rotating" role="presentation">
                    rotate_right
                </i>
            }
            {!isProcessing && children}
        </button>
    );
}

export default ConfirmShowHoursButton;
