import React from 'react';

function ClipboardButton({ value, style }) {
    function onClick() {
        navigator.clipboard.writeText(value);
    }

    if (!value) return null;

    return (
        <button style={style} className="clipboard-button" onClick={onClick}>
            <span className="material-icons">
                content_copy
            </span>
        </button>
    );
}

export default ClipboardButton;
