import React, { useState, useEffect } from 'react';

import firebase from '../firebase';
import Loader from './Loader';

function BroadcastDetails({ broadcastId, authUserBroadcasts }) {
    const [loading, setLoading] = useState(false);
    const [broadcast, setBroadcast] = useState(null);

    useEffect(() => {
        getBroadcastDetails();
    }, [broadcastId]);

    function getBroadcastDetails() {
        if (!broadcastId || !authUserBroadcasts.includes(broadcastId)) return;

        setLoading(true);
        firebase.getBroadcastDetails(broadcastId)
            .then(broadcastData => {
                setBroadcast(broadcastData);
                setTimeout(() => setLoading(false), 400);
            })
            .catch(err => {
                alert(`Something went wrong when fetching broadcast details. ${err.message}`);
            });
    }

    if (loading) return (
        <div style={{ padding: '48px 0' }}>
            <Loader />
        </div>
    );

    if (!broadcast) return (
        <div>
            No broadcast found
        </div>
    );

    return (
        <div>
            Broadcast id:{" "}
            {broadcastId}
            <br />
            Broadcast created:{" "}
            {broadcast.createdAtDate && broadcast.createdAtDate.toDateString()}
        </div>
    );
}

export default BroadcastDetails;
