import React, { useState } from 'react';

import firebase from '../firebase';
import { validateEmail } from '../utils/validationHelpers';

function SubscriptionLinkModal({ setModalOpen, searchResult, pathname, setSearchResult }) {
    const [accountEmail, setAccountEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const subscriptionEmail = searchResult && searchResult.userEmails && searchResult.userEmails[0];

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value } = e.target;
        setAccountEmail(value.trim().toLowerCase());
    }

    function handleSubmit(e) {
        e.preventDefault();

        setIsSubmitting(true);

        if (!validateEmail(accountEmail)) {
            alert('Please enter a valid email address.');
            setIsSubmitting(false);
            return;
        }

        if (accountEmail === subscriptionEmail) {
            alert('User email should not match the subscription email.');
            setIsSubmitting(false);
            return;
        }

        firebase.getCustomerInfo(accountEmail, pathname)
            .then(response => {
                const customerInfo = response.data;
                if (!customerInfo.firebaseUserUids || !customerInfo.firebaseUserUids.length) throw new Error('No user account found.');

                if (customerInfo.firebaseUserUids.length > 1) throw new Error('Multiple user accounts found.');

                return firebase.linkSubscriptionEmail(customerInfo.firebaseUserUids[0], subscriptionEmail);
            })
            .then(() => firebase.getCustomerInfo(subscriptionEmail, pathname))
            .then(response => {
                setSearchResult(response.data);
                setIsSubmitting(false);
                setAccountEmail('');
                onCancelClick();
            })
            .catch(err => {
                setIsSubmitting(false);
                alert(`Something went wrong. ${err.message || ''}`);
            });
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="edit-modal__title">
                        Link subscription to existing user account
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="subscription-email">
                                Susbcription email
                            </label>
                            <input
                                id="subscription-email"
                                className="mdl-textfield__input"
                                value={subscriptionEmail}
                                readOnly
                                disabled />
                        </div>
                        <div className="mdl-textfield edit-modal__field">
                            <label className="mdl-typography--body-1" htmlFor="account-email">
                                Email of existing user account
                            </label>
                            <input
                                id="account-email"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name="email"
                                onChange={handleChange} />
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Save
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionLinkModal;
