import React from 'react';

function Loader() {
    return (
        <div className="loader">
            <span className="loader__label">
                Loading...
            </span>

            <div className="loader__line"></div>
        </div>
    );
}

export default Loader;
