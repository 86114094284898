import React from 'react';

import ClipboardButton from './ClipboardButton';
import { MODALS } from '../utils/constants';

function getSubscriptionUrl(platform, resourceId) {
    if (!platform) return null;

    if (platform === "stripe") return `https://dashboard.stripe.com/subscriptions/${resourceId}`;

    if (platform === "paypal") return `https://www.paypal.com/billing/subscriptions/${resourceId}`;

    return null;
}

function renderSubscriptionLink(subscription) {
    if (!subscription.resourceId) return (
        <span className="card__item-group-value">
            n/a
        </span>
    );

    const subscriptionUrl = getSubscriptionUrl(subscription.platform, subscription.resourceId);
    if (!subscriptionUrl) return (
        <span className="card__item-group-value">
            {subscription.resourceId}
        </span>
    );

    return (
        <a
            className="card__item-group-value mdl-color-text--indigo-500"
            target='_blank'
            rel="noopener noreferrer"
            href={subscriptionUrl}>
            {subscription.resourceId}
        </a>
    );
}

function SubscriptionDetails({ subscription, setModalOpen }) {
    const formattedEndTime = subscription.endTime
        ? new Date(subscription.endTime).toUTCString()
        : 'n/a';
    return (
        <div className="card">
            <div className="card__account-header">
                <h2 className="card__title">Subscription</h2>
                    <button className="card__edit-button mdl-color-text--indigo-500"
                        onClick={() => setModalOpen(MODALS.subscriptionLink)}>
                        <span className="material-icons" style={{ marginRight: '4px' }}>
                            link
                        </span>
                        Link
                    </button>
            </div>
            <div className="card__results">
                <div className="card__item-group">
                    <div>
                        <div className="card__item-group-key text-bold">Title:</div>
                        <span className="card__item-group-value">{subscription.title}</span>
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Status:</div>
                        <span className="card__item-group-value">{subscription.status}</span>
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Billing Period End Time:</div>
                        <span className="card__item-group-value">{formattedEndTime}</span>
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Email:</div>
                        <span className="card__item-group-value card__item-group-value--email">{subscription.subscriberEmail}</span>
                        <ClipboardButton value={subscription.subscriberEmail} />
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Subscription ID:</div>
                        {renderSubscriptionLink(subscription)}
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Platform:</div>
                        <span className="card__item-group-value">{subscription.platform}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionDetails;
