import React, { useState } from 'react';

import { validateUkVatNumber, sanitiseBankCode } from '../utils/validationHelpers';

const FIELDS = {
    currency: 'currency',
    region: 'region',
    name: 'name',
    address: 'address',
    postalCode: 'postalCode',
    city: 'city',
    ukVat: 'ukVat',
};

const REGIONS = {
    australia: 'Australia',
    belgium: 'Belgium',
    brazil: 'Brazil',
    canada: 'Canada',
    croatia: 'Croatia',
    cuba: 'Cuba',
    denmark: 'Denmark',
    egypt: 'Egypt',
    france: 'France',
    germany: 'Germany',
    hungary: 'Hungary',
    iceland: 'Iceland',
    ireland: 'Ireland',
    israel: 'Israel',
    italy: 'Italy',
    jamaica: 'Jamaica',
    japan: 'Japan',
    lebanon: 'Lebanon',
    mexico: 'Mexico',
    netherlands: 'Netherlands',
    nigeria: 'Nigeria',
    portugal: 'Portugal',
    southKorea: 'South Korea',
    spain: 'Spain',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    taiwan: 'Taiwan',
    turkey: 'Turkey',
    uganda: 'Uganda',
    unitedKingdom: 'United Kingdom',
    unitedStates: 'United States',
    otherEu: 'Other - Europe',
    other: 'Other',
};

const CURRENCY_REGIONS = [
    {"name": "Australia", "currency": "AUD"},
    {"name": "Belgium", "currency": "EUR"},
    {"name": "Brazil", "currency": "BRL"},
    {"name": "Canada", "currency": "CAD"},
    {"name": "Croatia", "currency": "EUR"},
    {"name": "Cuba", "currency": "USD"},
    {"name": "Denmark", "currency": "DKK"},
    {"name": "Egypt", "currency": "EGP"},
    {"name": "France", "currency": "EUR"},
    {"name": "Germany", "currency": "EUR"},
    {"name": "Hungary", "currency": "HUF"},
    {"name": "Iceland", "currency": "EUR"},
    {"name": "India", "currency": "INR"},
    {"name": "Ireland", "currency": "EUR"},
    {"name": "Israel", "currency": "ILS"},
    {"name": "Italy", "currency": "EUR"},
    {"name": "Jamaica", "currency": "USD"},
    {"name": "Japan", "currency": "JPY"},
    {"name": "Lebanon", "currency": "EUR"},
    {"name": "Mexico", "currency": "MXN"},
    {"name": "Netherlands", "currency": "EUR"},
    {"name": "Nigeria", "currency": "NGN"},
    {"name": "Portugal", "currency": "EUR"},
    {"name": "South Korea", "currency": "KRW"},
    {"name": "Spain", "currency": "EUR"},
    {"name": "Sweden", "currency": "SEK"},
    {"name": "Switzerland", "currency": "CHF"},
    {"name": "Taiwan", "currency": "USD"},
    {"name": "Turkey", "currency": "TRY"},
    {"name": "Uganda", "currency": "USD"},
    {"name": "United Kingdom", "currency": "GBP"},
    {"name": "United States", "currency": "USD"},
    {"name": "Other - Europe", "currency": "EUR"},
    {"name": "Other", "currency": "USD"},
];

const CURRENCIES = CURRENCY_REGIONS.reduce((acc, cur) => {
    if (acc.includes(cur["currency"])) return acc;

    acc.push(cur["currency"]);
    return acc;
}, []);

function currencyByRegion(regionName) {
   const currencyRegion = CURRENCY_REGIONS.find(cr => cr["name"] === regionName);
   return currencyRegion ? currencyRegion["currency"] : "GBP";
}

function PayoutModalRegion({ isSubmitting, setModalOpen, submitForm }) {
    const [region, setRegion] = useState(REGIONS.unitedKingdom);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [ukVat, setUkVat] = useState('');
    const [currency, setCurrency] = useState(currencyByRegion(REGIONS.unitedKingdom));
    const [inputErrors, setInputErrors] = useState([]);

    function handleChange(e) {
        const { name, value } = e.target;
        switch(name) {
            case FIELDS.region:
                setRegion(value);
                setCurrency(currencyByRegion(value));
                if (value !== REGIONS.unitedKingdom) setUkVat('');
                break;
            case FIELDS.name:
                setName(value);
                break;
            case FIELDS.address:
                setAddress(value);
                break;
            case FIELDS.postalCode:
                setPostalCode(value);
                break;
            case FIELDS.city:
                setCity(value);
                break;
            case FIELDS.ukVat:
                setUkVat(value);
                break;
            case FIELDS.currency:
                setCurrency(value);
                break;
        }
    }

    function validateFields(fields) {
        return Object.keys(fields).reduce((errors, key) => {
            if (!fields[key]) errors.push(FIELDS[key]);
            return errors;
        }, []);
    }

    function handleSubmit(e) {
        e.preventDefault();
        const sanitisedVatNumber = sanitiseBankCode(ukVat);
        const errors = validateFields({name, address, postalCode, city});

        if (region === REGIONS.unitedKingdom && !validateUkVatNumber(sanitisedVatNumber)) {
            errors.push(FIELDS.ukVat);
        }
        if (errors.length) return setInputErrors(errors);
        const personalDetails = { name, address, postalCode, city, currency, region, ukVat: sanitisedVatNumber };
        const payoutDetails = { currency, noAccount: true };
        submitForm(payoutDetails, personalDetails);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mdl-textfield edit-modal__field">
                <label className="mdl-typography--body-1" htmlFor={FIELDS.region}>
                    Country
                </label>
                <select className="mdl-textfield__input" value={region} onChange={handleChange} id={FIELDS.region} name={FIELDS.region}>
                    {Object.keys(REGIONS).map(regionKey => {
                        const regionName = REGIONS[regionKey];
                        return (<option key={regionKey} value={regionName}>{regionName}</option>);
                    })}
                </select>
            </div>
            <div className="mdl-textfield edit-modal__field">
                <label className="mdl-typography--body-1" htmlFor={FIELDS.name}>
                    Full name
                </label>
                <input
                    className="mdl-textfield__input edit-modal__input"
                    type="text"
                    name={FIELDS.name}
                    onChange={handleChange}
                    value={name} />
                {inputErrors.some(inputError => inputError === FIELDS.name)
                    && <span className="mdl-color-text--red mdl-typography--body-1">Please enter your name</span>}
            </div>
            <div className="mdl-textfield edit-modal__field">
                <label className="mdl-typography--body-1" htmlFor={FIELDS.address}>
                    Address
                </label>
                <input
                    className="mdl-textfield__input edit-modal__input"
                    type="text"
                    name={FIELDS.address}
                    onChange={handleChange}
                    value={address} />
                {inputErrors.some(inputError => inputError === FIELDS.address)
                    && <span className="mdl-color-text--red mdl-typography--body-1">Please enter your address</span>}
            </div>
            <div className="mdl-textfield edit-modal__field">
                <label className="mdl-typography--body-1" htmlFor={FIELDS.city}>
                    Town/City
                </label>
                <input
                    className="mdl-textfield__input edit-modal__input"
                    type="text"
                    name={FIELDS.city}
                    onChange={handleChange}
                    value={city} />
                {inputErrors.some(inputError => inputError === FIELDS.city)
                    && <span className="mdl-color-text--red mdl-typography--body-1">Please enter your city</span>}
            </div>
            <div className="mdl-textfield edit-modal__field">
                <label className="mdl-typography--body-1" htmlFor={FIELDS.postalCode}>
                    Postal code
                </label>
                <input
                    className="mdl-textfield__input edit-modal__input"
                    type="text"
                    name={FIELDS.postalCode}
                    onChange={handleChange}
                    value={postalCode} />
                {inputErrors.some(inputError => inputError === FIELDS.postalCode)
                    && <span className="mdl-color-text--red mdl-typography--body-1">Please enter your postal code</span>}
            </div>
            <div className="mdl-textfield edit-modal__field">
                <label className="mdl-typography--body-1" htmlFor={FIELDS.currency}>
                    Currency of your bank account
                </label>
                <select className="mdl-textfield__input" value={currency} onChange={handleChange} id={FIELDS.currency} name={FIELDS.currency}>
                    {Object.keys(CURRENCIES).map(currencyKey => {
                        const currency = CURRENCIES[currencyKey];
                        return (<option key={currencyKey} value={currency}>{currency}</option>);
                    })}
                </select>
            </div>
            {region === REGIONS.unitedKingdom && (
                <div className="mdl-textfield edit-modal__field">
                    <label className="mdl-typography--body-1" htmlFor={FIELDS.ukVat}>
                    UK VAT number (if you have one)
                    </label>
                    <input
                        className="mdl-textfield__input edit-modal__input"
                        type="text"
                        name={FIELDS.ukVat}
                        onChange={handleChange}
                        value={ukVat} />
                    {inputErrors.some(inputError => inputError === FIELDS.ukVat)
                        && <span className="mdl-color-text--red mdl-typography--body-1">Please enter a valid UK VAT number</span>}
                </div>
            )}
            <div className="edit-modal__buttons">
                <button
                    className="mdl-button mdl-button--raised button-responsive"
                    disabled={isSubmitting}
                    type="button"
                    onClick={() => setModalOpen(false)}>
                    Cancel
                </button>
                <button
                    className="mdl-button mdl-button--raised button-blue button-responsive"
                    disabled={isSubmitting}
                    type="submit">
                    Submit
                    {isSubmitting &&
                        <i className="material-icons rotating" role="presentation">
                            rotate_right
                        </i>
                    }
                </button>
            </div>
        </form>
    );
}

export default PayoutModalRegion;
