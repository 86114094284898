const ONE_DAY_MS = 8.64e+7;
const MONTHS_LIMIT = 6;

function generateReportMonths(date, months = [], count = 0) {
    if (count >= MONTHS_LIMIT) return months;

    const [year, month] = date.toISOString().split("-");
    const monthName = date.toLocaleString("default", { month: "long" });
    const availableMonth = {
        monthName,
        reportMonth: `${year}-${month}`
    };
    months.push(availableMonth);

    const startMonthDate = new Date(`${year}-${month}-01`);
    const lastMonthDate = new Date(startMonthDate.getTime() - ONE_DAY_MS);
    return generateReportMonths(lastMonthDate, months, count + 1);
}

module.exports = {
    generateReportMonths,
};
