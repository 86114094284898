function sanitiseShowAlias(showAlias) {
    return showAlias.trim()
        .replace(/\/$/, '').split('/').pop()
        .replace(/\s/g, '-');
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function sanitiseBankCode(code) {
    return (code || '').replace(/[- ]/g, '');
}

function validateUkVatNumber(vatNumber) {
    if (!vatNumber) return true;

    return /^[a-zA-Z]{0,2}\d{9,}$/.test(vatNumber);
}

module.exports = {
    sanitiseShowAlias,
    sanitiseBankCode,
    validateEmail,
    validateUkVatNumber,
};
