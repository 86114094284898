import React, { useEffect, useState } from 'react';

import firebase from '../firebase';

function CustomerSearch({ pathname, setSearchResult, customerEmail, setCustomerEmail }) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setSearchResult(null);
    }, [pathname]);

    function handleChange(e) {
        const { value } = e.target;
        setCustomerEmail(value);
    }

    function resetForm() {
        setCustomerEmail('');
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (customerEmail.length < 4) {
            return alert('Please enter an email address or User UID.');
        }

        setIsSubmitting(true);
        firebase.getCustomerInfo(customerEmail, pathname).then(response => {
            setSearchResult(response.data);
            setIsSubmitting(false);
        }).catch(() => {
            setIsSubmitting(false);
            alert('Meh, something went wrong when trying to find subscription details.');
        });
    }

    return (
        <div className="customer-search">
            <i className="material-icons" role="presentation">
                search
            </i>
            <form className="customer-search__form" action="#" onSubmit={handleSubmit}>
                <div className="mdl-textfield customer-search__text-field-container">
                    <div className="customer-search__input-container">
                        <input
                            className="mdl-textfield__input customer-search__input"
                            type="text"
                            name="subscriber-email"
                            value={customerEmail}
                            onChange={handleChange}
                            placeholder="User email or UID"
                            aria-label="User email or UID" />
                    </div>
                </div>
            </form>
            {isSubmitting && (
                <i className="material-icons rotating" role="presentation">
                    rotate_right
                </i>
            )}
            <i
                className="material-icons customer-search__clear"
                role="presentation"
                onClick={resetForm}>
                close
            </i>
        </div>
    );
}

export default CustomerSearch;
