import React from 'react';

import firebase from '../firebase';

function AuthorisationError({ email }) {
    const subject = encodeURIComponent('NTS Desk sign-in issue');

    return (
        <div className="modal-container">
            <div className="modal-container__modal auth-modal mdl-shadow--2dp">
                <div className="auth-modal__logo">
                    <img src="/img/logo-black.svg" alt="NTS Logo" />
                    <h3>Desk</h3>
                </div>
                <div className="auth-modal__unauthorised">
                    <div className="auth-modal__unauthorised-email">
                        {email}
                    </div>
                    <div className="auth-modal__unauthorised-message">
                        <span className="material-icons mdl-color-text--red">
                            error
                        </span>
                        <div>
                            <div>
                                Something went wrong...
                            </div>
                            <div className="auth-modal__unauthorised-message-body mdl-color-text--grey-700">
                                <a href={`mailto:support@ntslive.co.uk?subject=${subject}`}>
                                    Get in touch
                                </a>
                                {' '} and we&#39;ll get it sorted.
                            </div>
                        </div>
                    </div>
                    <button onClick={() => firebase.signOut()}>
                        Back to sign in
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AuthorisationError;
