import React from 'react';

import AccountDetails from './AccountDetails';
import DeviceDetails from './DeviceDetails';
import RecommendationsDetails from './RecommendationsDetails';
import SubscriptionDetails from './SubscriptionDetails';

function SearchResults({ pathname, searchResult, setModalOpen }) {
    if (!searchResult) return null;

    return (
        <div>
            <AccountDetails pathname={pathname} searchResult={searchResult} setModalOpen={setModalOpen} />
            {searchResult.subs && searchResult.subs.map((subscription, i) => {
                return <SubscriptionDetails setModalOpen={setModalOpen} subscription={subscription} key={`subscription${i}`} />;
            })}
            {searchResult.deviceIds && searchResult.deviceIds.map((deviceId, i) => {
                const deviceSetting = searchResult.deviceSettings.find(ds => ds.deviceId === deviceId);
                return <DeviceDetails deviceId={deviceId} deviceSetting={deviceSetting} key={`subscription${i}`} />;
            })}
            {searchResult.recommendations &&
                <RecommendationsDetails recommendations={searchResult.recommendations} />
            }
        </div>
    );
}

export default SearchResults;
