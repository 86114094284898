import React from 'react';

import ClipboardButton from './ClipboardButton';

function DeviceDetails({ deviceId, deviceSetting }) {
    const liveNowSetting = deviceSetting
        && deviceSetting.notificationSettings
        && deviceSetting.notificationSettings.live_now;
    const notificationsId = deviceSetting && deviceSetting.notificationsId;

    function displayLiveNowSetting(setting) {
        if (setting === true) return ' true';

        if (setting === false) return ' false';

        return ' n/a';
    }
    return (
        <div className="card">
            <h2 className="card__title">Device</h2>
            <div className="card__results">
                <div className="card__item-group">
                    <div>
                        <div className="card__item-group-key text-bold">Device ID:</div>
                        <span className="card__item-group-value card__item-group-value--device-id">{deviceId}</span>
                        <ClipboardButton value={deviceId} />
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Notifications ID:</div>
                        <span className="card__item-group-value card__item-group-value--notifications-id text-overflow-ellipsis">{notificationsId || 'n/a'}</span>
                        <ClipboardButton value={notificationsId} />
                    </div>
                    <div>
                        <div className="card__item-group-key text-bold">Live Now Notification Settings:</div>
                        <span className="card__item-group-value">{displayLiveNowSetting(liveNowSetting)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeviceDetails;
