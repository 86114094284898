import React, { useState } from 'react';

import { validateEmail } from '../utils/validationHelpers';
import firebase from '../firebase';

function errorMessage(errMsg) {
    if (!errMsg) return '';

    if (errMsg.toLowerCase().includes('mailchimp')) {
        return `Failed to update Mailchimp member with new email. User's log-in email address has still been changed.`;
    }

    return errMsg;
}

function AccountEditModal({ pathname, setSearchResult, searchResult, setModalOpen, setCustomerEmail }) {
    const [newEmail, setNewEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const customerEmail = searchResult.userEmails[0].trim().toLowerCase();

    function handleChange(e) {
        const { value } = e.target;
        setNewEmail(value.trim().toLowerCase());
    }

    function handleSubmit(e) {
        e.preventDefault();

        setIsSubmitting(true);

        if (!validateEmail(customerEmail) || !validateEmail(newEmail)) {
            alert('One or more of the inputted email addresses are invalid.');
            setIsSubmitting(false);
            return;
        }

        if (customerEmail === newEmail) {
            alert('Please input a new email address.');
            setIsSubmitting(false);
            return;
        }

        firebase.updateCustomerEmail(customerEmail, newEmail)
            .catch(err => {
                setIsSubmitting(false);
                alert(`Something went wrong. ${errorMessage(err.message)}`);
            })
            .then(() => {
                return firebase.getCustomerInfo(newEmail, pathname);
            })
            .then(response => {
                setCustomerEmail(newEmail);
                setSearchResult(response.data);
                setIsSubmitting(false);
                setNewEmail('');
                onClick();
            });
    }

    function onClick() {
        setModalOpen(null);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="edit-modal__title">
                        Edit account details
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield edit-modal__field">
                            <label className="mdl-typography--body-1" htmlFor="current-email">
                                Current email
                            </label>
                            <input
                                id="current-email"
                                className="mdl-textfield__input"
                                value={customerEmail}
                                readOnly
                                disabled />
                        </div>
                        <div className="mdl-textfield edit-modal__field">
                            <label className="mdl-typography--body-1" htmlFor="new-email">
                                New email
                            </label>
                            <input
                                id="new-email"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name="email"
                                onChange={handleChange} />
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Save
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AccountEditModal;
