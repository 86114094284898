import React, { useState } from 'react';

import firebase from '../firebase';

function AccountDeleteModal({ setModalOpen, searchResult, pathname, setSearchResult }) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const email = searchResult && searchResult.userEmails && searchResult.userEmails[0];
    const firebaseUserUid = searchResult && searchResult.firebaseUserUids && searchResult.firebaseUserUids[0];

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        firebase.deleteUser(firebaseUserUid)
            .then(() => firebase.getCustomerInfo(email, pathname))
            .then(response => {
                setSearchResult(response.data);
                setIsSubmitting(false);
                onCancelClick();
            })
            .catch(err => {
                setIsSubmitting(false);
                alert(`Something went wrong. ${err.message || ''}`);
            });
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="edit-modal__title">
                        Are you sure you want to delete this account? This action cannot be undone.
                    </div>
                    <div className="mdl-textfield" style={{ width: '100%' }}>
                        <label className="mdl-typography--body-1" htmlFor="subscription-email">
                            Email address
                        </label>
                        <input
                            id="subscription-email"
                            className="mdl-textfield__input"
                            value={email}
                            readOnly
                            disabled />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                className="mdl-button mdl-button--raised button-red button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Delete
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AccountDeleteModal;
