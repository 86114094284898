const IS_NOT_PROD_REGEX = new RegExp(/localhost|integration|^192\./);

function isProduction() {
    if (typeof window === 'undefined') return false;

    return !(IS_NOT_PROD_REGEX.test(window.location.hostname));
}

module.exports = {
    isProduction
};
