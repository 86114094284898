import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import { AuthUser, HostShow, Roles } from './utils/models';
import { PATHNAMES } from './utils/constants';
import { isProduction } from './utils/environmentHelpers';

function expand(pathname) {
    const value = [ 'userEmails' ];
    switch(pathname) {
        case PATHNAMES.home:
            value.push('subs');
            break;
        case PATHNAMES.devices:
            value.push('deviceIds', 'deviceSettings');
            break;
        case PATHNAMES.recommendations:
            value.push('recommendations');
            break;
        case PATHNAMES.hosts:
            value.push('roles', 'shows');
            break;
    }
    return value;
}

function getFirebaseConfig() {
    if (!isProduction()) return {
        apiKey: "AIzaSyD8S9R9btaGGZLJ0bLoRgIgjrv9p4YxoQc",
        authDomain: 'nts-users-int.firebaseapp.com',
        databaseURL: 'https://nts-users-int.firebaseio.com',
        projectId: "nts-users-int",
    };

    return {
        apiKey: 'AIzaSyA4Qp5AvHC8Rev72-10-_DY614w_bxUCJU',
        authDomain: 'nts-ios-app.firebaseapp.com',
        databaseURL: 'https://nts-ios-app.firebaseio.com',
        projectId: "nts-ios-app",
    };
}

class Firebase {
    constructor() {
        const app = firebase.initializeApp(getFirebaseConfig());
        this.firestore = app.firestore();
        this.functions = app.functions('europe-west2');
        this.auth = firebase.auth();
        this.getHostShows = this.getHostShows.bind(this);
    }

    signIn(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    signOut() {
        return this.auth.signOut();
    }

    getRoles(currentUser) {
        return currentUser.getIdTokenResult()
            .then(idTokenResult => {
                if (!idTokenResult.claims) return new Roles(false, false);

                return new Roles(
                    idTokenResult.claims.admin,
                    idTokenResult.claims.host
                );
            });
    }

    getCustomerInfo(customerEmail, pathname) {
        const data = {
            customerEmail,
            expand: expand(pathname),
        };
        const getCustomerInfo = this.functions.httpsCallable('getCustomerInfoEurope');
        return getCustomerInfo(data);
    }

    updateCustomerEmail(customerEmail, newEmail) {
        const data = {
            customerEmail,
            newEmail,
        };
        const updateCustomerEmail = this.functions.httpsCallable('updateCustomerEmailAddressEurope');
        return updateCustomerEmail(data);
    }

    getDownloadHostsUrl() {
        const getUserToken = this.functions.httpsCallable('getUserTokenEurope');
        return getUserToken().then(resp => {
            const token = resp.data;
            const config = getFirebaseConfig();
            return `https://europe-west2-${config.projectId}.cloudfunctions.net/downloadHostsEurope?token=${token}`;
        });
    }

    onAuthStateChanged(callback) {
        return this.auth.onAuthStateChanged(firebaseUser => {
            if (!firebaseUser) return callback(null);

            this.getRoles(firebaseUser)
                .then(roles => {
                    callback(new AuthUser(firebaseUser, roles));
                });
        });
    }

    updateHostRole(customerEmail, isHost, shows) {
        const data = {
            customerEmail,
            isHost,
            shows,
        };
        const updateHostRole = this.functions.httpsCallable('updateHostRoleEurope');
        return updateHostRole(data);
    }

    getHostShows() {
        const getHostShows = this.functions.httpsCallable('getHostShowsEurope');
        return getHostShows()
            .then(response => {
                return response.data.map(hostShowData => new HostShow(hostShowData));
            });
    }

    confirmShowPayout(payoutDetails, showAlias, personalDetails, amountCents) {
        const data = {
            amountCents,
            showAlias,
            payoutDetails,
            personalDetails,
        };
        const confirmShowPayout = this.functions.httpsCallable('confirmShowPayoutEurope');
        return confirmShowPayout(data);
    }

    confirmShowHours(hours, showAlias) {
        const data = {
            hours,
            showAlias,
        };
        const confirmShowHours = this.functions.httpsCallable('confirmShowHoursEurope');
        return confirmShowHours(data);
    }

    linkSubscriptionEmail(firebaseUserUid, subscriptionEmail) {
        const linkSubscriptionEmail = this.functions.httpsCallable('linkSubscriptionEmailEurope');
        return linkSubscriptionEmail({ firebaseUserUid, subscriptionEmail });
    }

    deleteUser(firebaseUserUid) {
        const deleteUser = this.functions.httpsCallable('deleteUserEurope');
        return deleteUser({ firebaseUserUid });
    }

    getListenerPicks(reportMonth) {
        const getListenerPicks = this.functions.httpsCallable('getListenerPicksEurope');
        return getListenerPicks({ reportMonth });
    }

    getAdminUser() {
        return this.firestore
            .collection('admin_users')
            .doc(this.auth.currentUser.uid)
            .get()
            .then(doc => {
                const data = doc.data();
                return {
                    broadcasts: data.broadcasts ? data.broadcasts.reverse() : [],
                };
            });
    }

    getBroadcastDetails(broadcastId) {
        return this.firestore
            .collection('broadcasts')
            .doc(broadcastId)
            .get()
            .then(doc => {
                const data = doc.data();
                return {
                    id: broadcastId,
                    createdAtDate: data.created_at ? data.created_at.toDate() : null,
                };
            });
    }

    getRecentBroadcasts() {
        return this.firestore
            .collection('broadcasts')
            .orderBy('created_at', 'desc')
            .limit(100)
            .get()
            .then(querySnapshot => querySnapshot.docs.map(doc => doc.id));
    }

    async writeBroadcast() {
        const broadcastDocRef = await this.firestore
            .collection('broadcasts')
            .add({
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
            });

        return broadcastDocRef.id;
    }

    async getHostUserByEmail(email) {
        const querySnapshot = await this.firestore
            .collection('admin_users')
            .where('user_email', '==', email)
            .get();
        if (querySnapshot.empty) return null;

        const doc = querySnapshot.docs[0];
        const { host } = doc.data();
        if (!host) return null;

        return doc;
    }

    async createBroadcast(email) {
        if (!email) return this.writeBroadcast();

        const hostUser = await this.getHostUserByEmail(email);
        if (!hostUser) throw new Error('Host user not found for given email address.');

        const newBroadcastId = await this.writeBroadcast();
        await hostUser.ref.update({
            broadcasts: firebase.firestore.FieldValue.arrayUnion(newBroadcastId),
        });
        return newBroadcastId;
    }
}

export default new Firebase();
