import React, { useState, useEffect } from 'react';

import firebase from '../firebase';
import ClipboardButton from './ClipboardButton';
import Loader from './Loader';
import { generateReportMonths } from '../utils/listenerPicksHelpers';

function ListenerPicks() {
    const [loading, setLoading] = useState(false);
    const [reportMonths, setReportMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [listenerPicks, setListenerPicks] = useState([]);

    useEffect(getReportMonths, []);
    useEffect(getListenerPicks, [selectedMonth]);

    function getListenerPicks() {
        if (!selectedMonth) return;

        setLoading(true);
        firebase.getListenerPicks(selectedMonth)
            .then(response => {
                setListenerPicks(response.data.results);
                setTimeout(() => setLoading(false), 400);
            })
            .catch(err => {
                alert(`Something went wrong when fetching listener picks. ${err.message}`);
            });
    }

    function getReportMonths() {
        const months = generateReportMonths(new Date());
        setReportMonths(months);
        setSelectedMonth(months[0].reportMonth);
    }

    function handleChange(e) {
        setSelectedMonth(e.target.value);
    }

    if (loading) return (
        <div style={{ padding: '48px 0' }}>
            <Loader />
        </div>
    );

    return (
        <div>
            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <select onChange={handleChange} value={selectedMonth} className="mdl-textfield__input" id="month" name="month">
                    {reportMonths.map(month => {
                        return (<option key={month.reportMonth} value={month.reportMonth}>{month.monthName}</option>);
                    })}
                </select>
                <label className="mdl-textfield__label" htmlFor="month"></label>
            </div>
            <table style={{ width: "100%", tableLayout: "fixed" }} className="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-shadow--2dp">
                <thead>
                    <tr>
                    <th className="mdl-data-table__cell--non-numeric">Episode</th>
                    <th>No. picks</th>
                    <th>Picked by</th>
                    </tr>
                </thead>
                <tbody>
                    {listenerPicks.map(pick => {
                        return (
                            <tr key={pick.episodeAlias}>
                                <td className="mdl-data-table__cell--non-numeric">
                                    <a target="_blank" href={`https://www.nts.live/shows/${pick.showAlias}/episodes/${pick.episodeAlias}`} rel="noreferrer">
                                        {pick.episodeAlias}
                                    </a>
                                </td>
                                <td>{pick.count}</td>
                                <td style={{ overflowX: "hidden", textOverflow: "ellipsis" }}>
                                    <span className="clipboard-button-text">{pick.pickedBy.join(", ")}</span>
                                    <ClipboardButton style={{ position: "absolute", right: 0, marginTop: "1px" }} value={pick.pickedBy.join(", ")} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ListenerPicks;
